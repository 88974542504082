import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Page from '../components/Page'
import SEO from '../components/SEO'
import Schema from '../components/Schema'
import LeadSpot from '../components/solutions/LeadSpot'
import LeadStreamer from '../components/primary/LeadStreamer'
import CallToAction from '../components/primary/CallToAction'
import Newsletter from '../components/Newsletter'

import { isEven } from '../utils/num'

const ApiPage = () => {
  const { markdownRemark } = useStaticQuery(graphql`
    query ApiPageQuery {
      markdownRemark(frontmatter: { path: { eq: "/api" } }) {
        frontmatter {
          title
          description
          path
          lead {
            ...LeadSpotFragment
            ...LeadStreamerFragment
          }
          benefitsYou {
            blurbsOne {
              title
              text
              icon
            }
          }
          manufacturer {
            blurbs {
              title
              text
              image {
                modal: childImageSharp {
                  ...SharpImageModalFragment
                }
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          wholesaler {
            blurbs {
              title
              text
              image {
                modal: childImageSharp {
                  ...SharpImageModalFragment
                }
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ...CallToActionFragment
        }
      }
    }
  `)

  const pageData = markdownRemark.frontmatter
  const [tab, setTab] = useState('manufacturer')

  return (
    <Page className="p-solutions p-solutions--caterers has-fingerPrints">
      <SEO title={pageData.title} description={pageData.description} />
      <Schema
        breadcrumbs={[
          {
            name: pageData.title,
            uri: pageData.path,
          },
        ]}
      />

      <LeadSpot
        title={pageData.lead.spot.title}
        header={pageData.lead.spot.header}
        text={pageData.lead.spot.text}
        imageFolder="api"
      />

      <LeadStreamer
        id={pageData.lead.streamer.id}
        title={pageData.lead.streamer.title}
        image={pageData.lead.streamer.image}
        imageWidth={pageData.lead.streamer.imageWidth}
        fill="#f4f4f4"
      />

      <section
        id="benefits-to-you"
        className="c-section c-section--forBenefits c-section--forBenefits--you"
      >
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-forBenefits">
              {pageData.benefitsYou.blurbsOne.map((blurb) => (
                <div key={blurb.title} className="c-forBenefitsInfo row">
                  <div className="c-forBenefitsInfo__block c-forBenefitsInfo__block--icon col-12 col-md-3">
                    <img
                      className="c-forBenefitsInfo__icon img-fluid"
                      src={`/images/icons/${blurb.icon}.svg`}
                    />
                  </div>

                  <div className="c-forBenefitsInfo__block c-forBenefitsInfo__block--text col-12 col-md-7">
                    <h2 className="c-forBenefitsInfo__header">{blurb.title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: blurb.text }} />
                  </div>
                </div>
              ))}

              <div className="row my-5">
                <div className="col-12 col-lg-6 offset-lg-3 px-5">
                  <ul className="c-pricePlanToggle mb-2">
                    <li
                      className={`c-pricePlanToggle__link ${
                        tab === 'manufacturer' ? 'is-active' : ''
                      }`}
                      onClick={() => setTab('manufacturer')}
                    >
                      Manufacturers
                    </li>
                    <li
                      className={`c-pricePlanToggle__link ${
                        tab === 'wholesaler' ? 'is-active' : ''
                      }`}
                      onClick={() => setTab('wholesaler')}
                    >
                      Wholesalers
                    </li>
                  </ul>
                </div>
              </div>

              <div
                style={{ display: tab === 'manufacturer' ? 'block' : 'none' }}
              >
                {pageData.manufacturer.blurbs.map((blurb, i) => (
                  <div
                    key={blurb.title}
                    className={`c-forBenefitsCard ${
                      !isEven(i) ? 'c-forBenefitsCard--reversed' : ''
                    }`}
                  >
                    <div className="c-forBenefitsCard__block c-forBenefitsCard__block--text">
                      <h5 className="c-forBenefitsCard__header">
                        {blurb.title}
                      </h5>
                      <div className="c-forBenefitsCard__text">
                        <p dangerouslySetInnerHTML={{ __html: blurb.text }} />
                      </div>
                    </div>

                    <div className="c-forBenefitsCard__block c-forBenefitsCard__block--image">
                      <Img
                        fluid={blurb.image.childImageSharp.fluid}
                        title={blurb.title}
                        alt={blurb.title}
                        className="c-forBenefitsCard__image img-fluid"
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div style={{ display: tab === 'wholesaler' ? 'block' : 'none' }}>
                {pageData.wholesaler.blurbs.map((blurb, i) => (
                  <div
                    key={blurb.title}
                    className={`c-forBenefitsCard ${
                      isEven(i) ? 'c-forBenefitsCard--reversed' : ''
                    }`}
                  >
                    <div className="c-forBenefitsCard__block c-forBenefitsCard__block--text">
                      <h5 className="c-forBenefitsCard__header">
                        {blurb.title}
                      </h5>
                      <div className="c-forBenefitsCard__text">
                        <p dangerouslySetInnerHTML={{ __html: blurb.text }} />
                      </div>
                    </div>

                    <div className="c-forBenefitsCard__block c-forBenefitsCard__block--image">
                      <Img
                        fluid={blurb.image.childImageSharp.fluid}
                        title={blurb.title}
                        alt={blurb.title}
                        className="c-forBenefitsCard__image img-fluid"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <CallToAction ctaData={pageData.signUpCta} fill={`#f4f4f4`} />

      <Newsletter />
    </Page>
  )
}

export default ApiPage
